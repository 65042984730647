import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from '../components/header'
import '../css/bootstrap.min.css'
import '../css/fonts.css'
import '../css/index.css'
import '../css/nav.css'

const title = "Should you move to Sibley, Iowa?"

const Layout = ({ children }) => (
  <div>
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content:
            "I'll give you my opinion on both the good and the bad of Sibley, Iowa, so you can make an informed decision.",
        },
        {
          name: 'keywords',
          content: 'sibley, iowa, city council, blood plant, idp, aclu',
        },
        { name: 'msvalidate.01', content: '4D50DA62EDC9DD1E9408EC94F9D5AFD2' },
      ]}
    >
      <script defer data-domain="shouldyoumovetosibleyia.com" src="https://plausible.io/js/plausible.js"></script>
    </Helmet>
    <Header siteTitle={title} />
    <div className="container body-content">
      {children}
      <hr />
      <footer>
        <div className="row">
          <div className="col-md-10 col-md-offset-1 col-sm-12">
            <p>
              Everything you've just read is the
              <strong> opinion</strong> of this website's creator. Only a fool
              would take it as fact.
            </p>
            <p>
              &copy;
              <span className="date-year"> {new Date().getFullYear()}</span>.
              Contact me at{' '}
              <a href="mailto:joshua@nozzlegear.com">joshua@nozzlegear.com</a>.
            </p>
            <script dangerouslySetInnerHTML={{ __html: '' }} />
          </div>
        </div>
      </footer>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
