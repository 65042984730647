import React from 'react'
import Link from 'gatsby-link'

const Header = ({ siteTitle }) => (
  // I'm not sure what the bug is here, but in Chrome if the header and footer (display flex) aren't wrapped in a div they become 0 height and the padding of other elements overtake them.
  <div>
    <header className="header">
      <Link to="/" className="logo">
        {siteTitle}
      </Link>
      <input type="checkbox" id="menu-btn" className="menu-btn" />
      <label htmlFor="menu-btn" className="menu-icon">
        <span className="navicon" />
      </label>
      <ul className="menu">
        <li>
          <Link to="/">The Good and The Bad</Link>
        </li>
        <li>
          <Link to="/blood-plant">The "Blood Plant"</Link>
        </li>
        <li>
          <Link to="/free-speech">First Amendment Lawsuit</Link>
        </li>
        <li>
          <a href="https://action.aclu.org/give/support-aclu-iowa?utm_source=shouldyoumovetosibleyia.com" target="_blank" rel="noreferrer">
            Donate to the ACLU
          </a>
        </li>
        {/* <li>
          <a href="http://sibleycouncilminutes.com" target="_blank">
            City Council Minutes
          </a>
        </li> */}
      </ul>
    </header>
  </div>
)

export default Header
